import { ApolloQueryResult } from "@apollo/client";
import { ContentBlock } from "components/admin/custom-blocks/types";
import { BookCard } from "components/book-card";
import { TriangleBg } from "components/decorative/triangle-bg";
import { EventCard } from "components/event-card";
import { Layout } from "components/layout";
import { PostCard } from "components/post-card";
import gql from "graphql-tag";
import {
  BookCardDataFragmentDoc,
  EventCardDataFragmentDoc,
  GetPageContentDocument,
  GetPageContentQueryHookResult,
  HomePageContentQueryVariables,
  PostCardDataFragmentDoc,
  useHomePageContentQuery,
} from "lib/graphql-generated";
import { addApolloState, initializeApollo } from "lib/hooks/apollo-client";
import { GetServerSideProps } from "next";
import Link from "next/link";

const HOME_PAGE = gql`
  query HomePageContent(
    $books: [bigint!]
    $now: timestamp
    $posts: [uuid!]
    $events: [uuid!]
  ) {
    books(where: { isbn: { _in: $books } }, limit: 8) {
      ...BookCardData
    }
    posts(
      where: { id: { _in: $posts } }
      limit: 4
      order_by: { created_at: desc }
    ) {
      ...PostCardData
    }
    upcomingEvents: events(
      where: {
        _and: [{ end_time: { _gte: $now } }, { is_cancelled: { _eq: false } }]
      }
      limit: 2
      order_by: { start_time: asc }
    ) {
      ...EventCardData
    }
    pastEvents: events(
      where: {
        _and: [{ id: { _in: $events } }, { is_cancelled: { _eq: false } }]
      }
      limit: 1
    ) {
      ...EventCardData
    }
  }
  ${BookCardDataFragmentDoc}
  ${PostCardDataFragmentDoc}
  ${EventCardDataFragmentDoc}
`;

interface Props {
  now: string;
  isbns: number[];
  postIds: string[];
  eventIds: string[];
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const client = initializeApollo();
  const now = new Date().toISOString();
  const { data }: ApolloQueryResult<GetPageContentQueryHookResult["data"]> =
    await client.query({
      query: GetPageContentDocument,
      variables: { title: "home" },
    });

  const booksBlock = data.pages_by_pk.content.data.find(
    (block) => block.id === 1
  ) as ContentBlock<"books">;

  const isbns = booksBlock?.data.value.map((b) => b.data.isbn) || [];

  const postsBlock = data.pages_by_pk.content.data.find(
    (block) => block.id === 2
  ) as ContentBlock<"posts">;

  const postIds = postsBlock?.data.value.map((p) => p.data.id) || [];

  const eventsBlock = data.pages_by_pk.content.data.find(
    (block) => block.id === 3
  ) as ContentBlock<"events">;
  const eventIds = eventsBlock?.data.value.map((e) => e.data.id) || [];

  await client.query({
    query: HOME_PAGE,
    variables: {
      books: isbns,
      posts: postIds,
      events: eventIds,
      now: now,
    } as HomePageContentQueryVariables,
  });
  return addApolloState(client, {
    props: {
      now: now,
      isbns: isbns,
      postIds: postIds,
      eventIds: eventIds,
    } as Props,
  });
};

export const Index = ({ now, isbns, postIds, eventIds }: Props) => {
  const { data, loading, error } = useHomePageContentQuery({
    variables: { books: isbns, posts: postIds, events: eventIds, now: now },
  });

  const books = data?.books;
  const lists = data?.posts;
  const upcomingEvents = data?.upcomingEvents;
  const pastEvents = data?.pastEvents;

  return (
    <>
      <Layout pageName='Home' className='bg-green-light relative flex'>
        <TriangleBg />
        <div className='[ grid grid-cols-1 gap-4 px-4 py-4 ] [ lg:grid-cols-3 lg:gap-10 lg:py-10 ] [ 2xl:px-0 ]  mx-auto z-10 relative w-full max-w-screen-2xl'>
          <section className='[ bg-white grid gap-4 p-4 col-span-full mx-auto w-full ] [ lg:col-span-3  ] grid-cols-2 md:grid-cols-4'>
            <header className='col-span-full pb-4'>
              <h2 className=' uppercase font-header tracking-wide text-xl '>
                <Link href='/posts'>
                  <a>Read Think Act</a>
                </Link>
              </h2>
            </header>
            {lists?.map((list) => (
              <PostCard post={list} key={list.id} />
            ))}
            <Link href='/posts'>
              <a className='col-span-full underline uppercase font-header tracking-wide px-2 pt-1 w-full mt-auto hover:text-red-default cursor-pointer'>
                Browse our Lists and other posts...
              </a>
            </Link>
          </section>

          <section className='[ order-3 bg-white grid gap-4 p-4 col-span-full mx-auto w-full ] [ lg:order-2 lg:col-span-2 ] grid-cols-2 md:grid-cols-4 '>
            <header className='col-span-full'>
              <h2 className='uppercase font-header tracking-wide text-xl'>
                <Link href='/browse'>
                  <a>New & Noteworthy</a>
                </Link>
              </h2>
            </header>
            {books?.map((book) => (
              <BookCard book={book} key={book.isbn} />
            ))}
            <Link href='/browse'>
              <a className='col-span-full underline uppercase font-header tracking-wide px-2 pt-1 w-full mt-auto hover:text-red-default cursor-pointer'>
                Browse our book collections...
              </a>
            </Link>
          </section>

          <section className='[ order-2 lg:order-3 ] flex flex-col'>
            <Link href='/browse'>
              <a className='bg-yellow-default border-4 border-yellow-default rounded-full mb-4 pb-2 pt-3 px-4 header text-xl text-center hover:bg-white hover:text-red-default'>
                Browse Categories
              </a>
            </Link>
            {upcomingEvents.length > 0 && (
              <section className='[ bg-white grid gap-4 p-4 col-span-full mx-auto w-full ] [ lg:col-span-1 ] grid-cols-1 mb-0 '>
                <header className='col-span-full'>
                  <h2 className='uppercase font-header tracking-wide text-xl'>
                    <Link href='/events'>
                      <a>Upcoming Events</a>
                    </Link>
                  </h2>
                </header>
                {upcomingEvents.map((event) => (
                  <EventCard event={event} key={event.id} />
                ))}
              </section>
            )}

            {pastEvents.length > 0 && (
              <section className='[ bg-white grid gap-4 p-4 col-span-full mx-auto w-full ] [ lg:col-span-1 ] grid-cols-1 mb-0 '>
                <header className='col-span-full'>
                  <h2 className='uppercase font-header tracking-wide text-xl'>
                    <Link href='/events'>
                      <a>Author Videos</a>
                    </Link>
                  </h2>
                </header>
                {pastEvents.map((event) => (
                  <EventCard event={event} key={event.id} />
                ))}
              </section>
            )}
            <Link href='/events'>
              <a className='col-span-full underline uppercase font-header tracking-wide px-4 pb-4 pt-1 w-full hover:text-red-default cursor-pointer bg-white mb-auto'>
                Browse all our events...
              </a>
            </Link>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Index;
