import { gql } from '@apollo/client';
import { EventCardDataFragment } from 'lib/graphql-generated';
import Link from 'next/link';
import { Button } from './buttons/button';

const EVENT_CARD_FRAGMENT = gql`
  fragment EventCardData on events {
    start_time
    slug
    id
    title
    speakers
    image
  }
`;

interface Props {
  event: EventCardDataFragment;
  className?: string;
}

export const EventCard = ({ event, className }: Props) => {
  const date = new Date(event.start_time);
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
    .format(date)
    .replace(/\//g, '.');

  const isPastEvent = new Date(Date.now()) > date;

  return (
    <article
      key={event.id}
      className={`bg-yellow-default relative overflow-hidden transform flex flex-col cursor-pointer group ${className}`}>
      <Link href={`/events/${event.slug}`}>
        <a className='h-full w-full flex flex-row overflow-hidden'>
          <time
            dateTime='2020-01-01'
            className='text-white group-hover:text-yellow-light header writing-mode-vertical text-7xl bg-gray-700 -ml-5 w-min whitespace-nowrap'>
            {formattedDate}
          </time>
          <div className='aspect-w-2 aspect-h-1 w-full'>
            <img
              loading='lazy'
              src={event.image}
              alt={`${event.title}, with ${event.speakers}`}
              className='w-full h-full'
            />
          </div>
        </a>
      </Link>
      <section className='w-full bg-gray-700 flex justify-end px-4 pt-1'>
        <Link href={`/events/${event.slug}`}>
          <a className='text-white header group-hover:underline group-hover:text-yellow-light'>
            View {!isPastEvent && '/ Book'}
          </a>
        </Link>
      </section>
    </article>
  );
};
