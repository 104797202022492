import { BookCardDataFragment } from 'lib/graphql-generated';
import Link from 'next/link';
import { Button } from './buttons/button';
import currency from 'currency.js';
import { gql } from '@apollo/client';
import { AddToBasket } from './buttons/add-to-basket';

const BOOK_CARD_FRAGMENT = gql`
  fragment BookCardData on books {
    isbn
    author
    title
    price
    discount_value
    is_discounted
    image
    stock
    track_stock
    tag_books_aggregate(where: { tag: { text: { _ilike: "%pre-order%" } } }) {
      aggregate {
        count
      }
    }
  }
`;

interface Props {
  book: BookCardDataFragment;
  className?: string;
  invertButton?: boolean;
}
export const BookCard = ({ book, className, invertButton }: Props) => {
  return (
    <article className={`flex flex-col pb-4 group ${className}`}>
      <header className='transform transition-transform group-hover:-translate-y-1 ease-in overflow-hidden cursor-pointer pb-2 flex h-full '>
        <Link href={`/book/${book.isbn}`}>
          <a className='h-full'>
            {book.image !== '' ? (
              <img
                loading='lazy'
                src={book.image}
                alt={`Image for ${book.title}`}
                className=' mx-auto bg-gray-200 h-auto w-full'
              />
            ) : (
              <div className=''></div>
            )}
          </a>
        </Link>
      </header>
      <section className='flex flex-col relative mt-auto space-y-2'>
        <Link href={`/book/${book.isbn}`}>
          <a>
            <dl
              className={`text-sm ${
                invertButton
                  ? 'group-hover:text-green-dark'
                  : 'group-hover:text-red-dark'
              } `}>
              <dt className='sr-only'>title</dt>
              <dd className='line-clamp-1'>{book.title}</dd>
              <dt className='sr-only'>author</dt>
              <dd className='line-clamp-1'>{book.author}</dd>
            </dl>
          </a>
        </Link>
        {book.stock === 0 && book.track_stock ? (
          <span className='text-red-dark text-sm font-bold my-auto'>
            Out of stock
          </span>
        ) : (
          <AddToBasket
            payload={{
              type: 'book',
              id: book.isbn,
              data: {
                price: book.price,
                discount: book.is_discounted ? book.discount_value : 0,
                quantity: 1,
                book: {
                  isbn: book.isbn,
                  image: book.image,
                  title: book.title,
                },
              },
            }}
            buttonType={invertButton ? 'secondary' : 'primary'}
            buttonSize='sm'
            className='mr-auto'>
            {book.tag_books_aggregate.aggregate.count > 0
              ? 'Pre-order '
              : 'Add to basket '}
            {currency(
              book.is_discounted
                ? book.price - book.discount_value
                : book.price,
              {
                fromCents: true,
                symbol: '£',
              }
            ).format()}
          </AddToBasket>
        )}
      </section>
    </article>
  );
};

export const BookNoImage = ({ book }: Props) => <div className='bg'></div>;
