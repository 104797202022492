import { Button, ButtonProps } from "./button";
import { useBasket } from "lib/hooks/basket/use-basket";
import { useIsInBasket } from "lib/hooks/basket/use-is-in-basket";
import { IBasketItem, ItemType } from "lib/basket/types";
import { useEffect, useState } from "react";

interface AddToBasketProps extends ButtonProps {
  payload: IBasketItem<ItemType>;
}

export const AddToBasket = ({
  buttonType,
  buttonSize,
  payload,
  ...props
}: AddToBasketProps) => {
  const [basket, reducer] = useBasket();
  const [disabled, setDisabled] = useState<boolean>(false);
  const isInBasket = useIsInBasket(payload.id);
  const handleAddToBasket = () => {
    reducer({
      type: "add",
      payload: {
        ...payload,
        data: {
          ...payload.data,
          quantity: 1,
        },
      },
    });
  };

  useEffect(() => {
    if (basket.items.some((item) => item.id === payload.id)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [basket.items]);

  return (
    <Button
      buttonType={buttonType}
      buttonSize={buttonSize}
      onClick={handleAddToBasket}
      disabled={disabled || props.disabled}
      {...props}>
      {disabled ? <>In Basket</> : props.children}
    </Button>
  );
};
