import { gql } from "@apollo/client";
import { PostCardDataFragment } from "lib/graphql-generated";
import Link from "next/link";

const POSTCARD_FRAGMENT = gql`
  fragment PostCardData on posts {
    id
    slug
    type
    title

    post_books(where: { book: { is_unlisted: { _eq: false } } }) {
      book {
        image
      }
    }
    image
  }
`;
interface Props {
  post: PostCardDataFragment;
  className?: string;
}
export const PostCard = ({ post, className }: Props) => (
  <article
    key={`post_${post.slug}`}
    className={`text-center relative group ${className}`}>
    <svg className='h-12 w-20 absolute -left-4 -top-6 transform group-hover:-rotate-12 group-hover:scale-110 ease-in transition-transform z-10 '>
      <use href={`#${post.type}`} />
    </svg>
    <Link href={`/posts/${post.slug}`}>
      <a className='flex flex-col h-full'>
        <header className='relative overflow-y-hidden aspect-w-1 aspect-h-1'>
          {post.type === "list" ? (
            <>
              {post.image ? (
                <img
                  className='w-full absolute bg-red-default h-full'
                  src={post.image}
                  alt=''
                />
              ) : (
                <div className='grid grid-cols-2'>
                  {post.post_books.map(
                    ({ book }, index) =>
                      index < 5 && (
                        <img src={book?.image} key={`img_${index}`}></img>
                      )
                  )}
                </div>
              )}
            </>
          ) : post.type === "review" ? (
            <img
              className='w-full absolute bg-red-default h-full'
              src={post.post_books[0]?.book.image}
              alt=''
            />
          ) : (
            <img
              className='w-full absolute bg-red-default h-full'
              src={
                post.image
                  ? post.image
                  : post.post_books[0]?.book.image
                  ? post.post_books[0]?.book.image
                  : null
              }
              alt=''
            />
          )}
        </header>
        <section className='bg-yellow-default  group-hover:bg-yellow-light w-full h-full p-4 pt-5 cursor-pointer flex'>
          <h1 className='uppercase font-header tracking-wide my-auto  mx-auto'>
            {post.title}
          </h1>
        </section>
      </a>
    </Link>
  </article>
);
