export const TriangleBg = () => {
  return (
    <div
      aria-hidden='true'
      className='absolute z-0 top-0 left-0 right-0 h-full w-screen [ space-y-40 ] [ xl:flex xl:flex-row xl:space-y-0 xl:flex-wrap ] overflow-hidden'>
      <svg
        viewBox='0 0 100 50'
        className='text-yellow-light w-full xl:w-1/2 xl:inline xl:my-auto overflow-visible'
        fill='currentColor'
        stroke='currentColor'>
        <polygon points='0,0 0,50 100,50' height='100%' width='100%' />
      </svg>
      <svg
        viewBox='0 0 100 50'
        className='text-yellow-light w-full xl:w-1/2 xl:inline xl:my-auto overflow-visible'
        fill='currentColor'
        stroke='currentColor'>
        <polygon points='100,0 0,50 100,50' height='100%' width='100%' />
      </svg>
      <svg
        viewBox='0 0 100 50'
        className='text-yellow-light w-full xl:w-1/2 xl:inline xl:my-auto overflow-visible'
        fill='currentColor'
        stroke='currentColor'>
        <polygon points='0,0 0,50 100,50' height='100%' width='100%' />
      </svg>
      <svg
        viewBox='0 0 100 50'
        className='text-yellow-light w-full xl:w-1/2 xl:inline xl:my-auto overflow-visible'
        fill='currentColor'
        stroke='currentColor'>
        <polygon points='100,0 0,50 100,50' height='100%' width='100%' />
      </svg>
      <svg
        viewBox='0 0 100 50'
        className='text-yellow-light w-full xl:w-1/2 xl:my-auto overflow-visible xl:hidden'
        fill='currentColor'
        stroke='currentColor'>
        <polygon points='0,0 0,50 100,50' height='100%' width='100%' />
      </svg>
      <svg
        viewBox='0 0 100 50'
        className='text-yellow-light w-full xl:w-1/2 xl:my-auto overflow-visible xl:hidden'
        fill='currentColor'
        stroke='currentColor'>
        <polygon points='100,0 0,50 100,50' height='100%' width='100%' />
      </svg>
    </div>
  );
};
